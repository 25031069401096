import { IMaxLoanTable, ILtvCalcInput, ILtvCalcOutput } from './types';

export const MinHousePrice = 70000;
const tblHealthy: IMaxLoanTable = {
  55: 27.5,
  56: 28.5,
  57: 29.5,
  58: 30.5,
  59: 31.5,
  60: 34.8,
  61: 35.8,
  62: 36.8,
  63: 37.8,
  64: 38.8,
  65: 39.8,
  66: 39.5,
  67: 40.5,
  68: 41.5,
  69: 42.5,
  70: 43.5,
  71: 44.5,
  72: 45.5,
  73: 46.5,
  74: 47.5,
  75: 48.5,
  76: 50.1,
  77: 51.3,
  78: 52,
  79: 52.5,
  80: 53.9,
  81: 54.5,
  82: 55.5,
  83: 56,
  84: 56,
  85: 56,
  86: 56,
  87: 56,
  88: 56,
  89: 56,
  90: 56,
};

const tblUnhealthy: IMaxLoanTable =  {
  55: 38.1,
  56: 39.2,
  57: 40.3,
  58: 41.4,
  59: 43.5,
  60: 43.6,
  61: 44.7,
  62: 46.3,
  63: 47.4,
  64: 48.5,
  65: 49.6,
  66: 50,
  67: 50.8,
  68: 51.9,
  69: 53,
  70: 53.5,
  71: 54.5,
  72: 54.5,
  73: 54.5,
  74: 54.5,
  75: 54.5,
  76: 54.5,
  77: 54.5,
  78: 54.5,
  79: 54.5,
  80: 54.5,
  81: 54.5,
  82: 54.5,
  83: 54.5,
  84: 54.5,
  85: 54.5,
  86: 54.5,
  87: 54.5,
  88: 54.5,
  89: 54.5,
  90: 54.5
}
// const isValid = x => (typeof x !== 'undefined' && x !== null && !isNaN(x));

export const getLtvValue = (input: ILtvCalcInput): ILtvCalcOutput => {
  const { clientAge, housePrice, ltvPlan } = input;
  const age = (clientAge > 90) ? 90 : clientAge;

  const valHealthy: number = tblHealthy[age];
  const MinLoan = (housePrice < 200000) ? 10000 :  Math.round(housePrice*0.05);
  const Healthy = Math.round( housePrice * 0.01 * valHealthy);
  const Unhealthy = Math.round( housePrice * 0.01 * tblUnhealthy[age]);
  const plans = { MinLoan, Healthy, Unhealthy };
  const result: ILtvCalcOutput = { plans, max: valHealthy, diff: 0, pct: {} };
  if (result.max > result.plans.Unhealthy) {
    result.max = result.plans.Unhealthy
  }
  result.diff = result.max - result.plans.MinLoan;
  result.pct = { 'MinLoan': result.plans.MinLoan * 100 / housePrice, 'Healthy': tblHealthy[age], 'Unhealthy': tblUnhealthy[age] };
  console.log('ltvPlan=', ltvPlan, result)
  return result;
};
