import React from 'react';
import './App.css';
import { LtvCalculatorComponent } from './../components/LtvCalculator/LtvCalculatorComponent';

function App() {
  return (
    <LtvCalculatorComponent />
  );
}

export default App;
