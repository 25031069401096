import React from 'react';
import styled from 'styled-components';

const InfoIconWrap = styled.div`
    position: relative;
    display: inline-block;
    top: 5px;
    left: 5px;
    img { width: 23px; height: 22px; }
`;

export const InfoIcon = () => {
    // const color = "#6c7286";
    // const size = 20;
    return (
      <InfoIconWrap>
        <img src="images/i-icon.png" alt="more info" />
      </InfoIconWrap>
    );
}
