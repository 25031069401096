import React from 'react';
import styled from 'styled-components';
import { InfoIcon } from '../../components/Form/InfoIcon';
import { toPounds } from '../../services/Formats';
import { IColorIndex, INumKeyStringMap } from './types';
import { ILtvCalcInput, ILtvCalcOutput } from './../../services/types';
import { MinHousePrice } from './../../services/LtvCalc';

const ErrorBox = styled.div`
  color: var(--color-error);
  text-align: center;
`;

/*
 > button.im {
   min-height: 300px;
   background: url(images/temperature-gray.png) no-repeat 0px 0px;
   background-size: 100%;
 }
 > button.im.active { background-image: url(images/temperature-lightblue.png); }
 > button.im:hover { background-image: url(images/temperature-lightblue.png); }
*/

const LevelsTopDiv = styled.div`
  position: relative;
  padding-bottom: 130px;
  @media(max-width:480px) { padding-bottom: 180px; }
`;

const Columns2 = styled.div`
  display: flex;
  align-items: flex-end;
  margin-bottom: 20px;
  .pct { 
    color: var(--color-main); 
    text-align: center;
    font-weight: bold;
    font-family: 'Nunito Sans',arial;
    font-size: 1.8rem;
  }
  > .person { width: 60%; }
`;

const LevelsDiv = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-around;

  .title {
     width: 100%;
     background: transparent;
     text-align: center;
     color: #000;
     font-size: 16px;
     font-weight: bold;
     font-family: 'Nunito Sans',arial;
  }
  .subtitle {
     text-align: center;
  }
  .amount {
    padding-top: 5px;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    color: var(--color-main);
  }
  @media(max-width:640px) { .title { font-size: 12px; font-weight: normal; } }
  @media(max-width:480px) { .title { font-size: 10px; font-weight: normal; } }

  .person {
    img { width: 75px; }
    @media(max-width:640px) { img { width: 55px; } }
    @media(max-width:480px) { img { width: 45px; } }
  }

  > button {
    width: 25%;
    border: none;
    cursor: pointer;
    outline: none !important;
    background: transparent;
  }

  .hint {
    display: none;
    position: absolute;
    top: 325px;
    left: 5%;
    width: 80%;
    background: #ededed;
    color: #444;
    font-family: 'Nunito Sans',arial;
    font-size: 16px;
    padding: 30px;

    @media(max-width:640px) { top: 265px; }
    @media(max-width:480px) { top: 250px; left: 0px; }
  }

  > button.active .hint { display: block; }
  > .level:hover .hint { display: block; }
  > .level .arrow { display: none; }
  > .level:hover .arrow { display: block; }

  .coins {
    width: 58px;
    background: url(images/coins-bk.png) 0px 0px no-repeat;
    background-size: 100%;
    position: relative;

    img {
      width: 100%;
      position: absolute;
      left: 0px;
      bottom: 0px;
    }
    @media(max-width:640px) { width: 45px; img { width: 100%; }}
    @media(max-width:680px) { width: 35px; img { width: 100%; }}
  }
  .pct {
    @media(max-width:640px) { font-size: 16px }
    @media(max-width:480px) { font-size: 14px }
  }
`;

const T: Array<string> = ['MinLoan', 'Healthy', 'Unhealthy'];
const TTL: Array<string> = ['Minimum Loan', 'Maximum Loan', 'Maximum Loan'];
const SUBTL: Array<string> = ['initial advance', 'for a healthy client', 'for an unhealthy client'];
const IMG: Array<string> = ['images/level-0.png?v=1', 'images/level-1.png?v=1', 'images/level-2.png?v=1'];
const HINT: Array<string> = [
  '',
  '',
  `It is possible to reach much higher LTVs based on the medical background of the client.
    A set of simple medical and lifestyle related questions are used to work out an enhanced LTV rate.
    Actual rates will vary according to the specific medical underwriting of each client.
  `
];


const GrayArrowDiv = styled.div`
  position: absolute;
  top: 315px;
  @media(max-width:640px) { top: 260px; }
  @media(max-width:480px) { top: 220px; }

`;

 const GrayArrow = (props: IColorIndex) => {
  const { color, index } = props;
  const positions: INumKeyStringMap = { 0: '20%', 1: '50%', 2: '85.5%' };
  if (!positions[index]) return null;

  return (
    <GrayArrowDiv className="arrow" style={{ left: positions[index] }}>
      <svg height="12" width="31" viewBox='0 0 31 12'>
		    <polygon style={{ fill: color }} points="0,11 15,0 29,11" />
      </svg>
    </GrayArrowDiv>
  );
}

const maxPctValue = 59 * 1.3;
const maxCoinsHeight = 130;
export const LevelsOfEnhancement = (props: any) => {
  const input: ILtvCalcInput = props.input;
  const output: ILtvCalcOutput = props.output.ltv;
  if (input.housePrice < MinHousePrice) return (
    <ErrorBox>
      The minimum value of home to qualify for a lifetime mortgage is {toPounds(MinHousePrice)}
    </ErrorBox>
  );
  return (
    <LevelsTopDiv>
      <LevelsDiv key={1}>
        {T.map((t: string, index: number) => (
          <div className="level" key={index}>
            <div>
              <Columns2>
                <div className='person'>
                  <img src={IMG[index]} alt={input.ltvPlan} />
                </div>
                <div>
                  <div className="pct">{Math.floor(output.pct[t])}%</div>
                  <div className="coins" style={{ height: output.pct[t] * maxCoinsHeight / maxPctValue }} >
                    <img src="images/coins-under-bk.png" alt="" />
                  </div>
                </div>
              </Columns2>
            </div>
            <div className={`title ${(t === input.ltvPlan) ? 'active' : ''}`}>
              {TTL[index]}
            </div>
            <div className="subtitle">{SUBTL[index]}</div>
            <div className="amount">
              {toPounds(output.plans[t])}
              {(!HINT[index]) ? false : <InfoIcon />}
            </div>

            {(!HINT[index]) ? false : <GrayArrow index={index} color='#ededed' />}
            {(!HINT[index]) ? false : <div className="hint">{HINT[index]}</div>}
          </div>
        ))}
      </LevelsDiv>

    </LevelsTopDiv>
  );
};
